<template>
  <div class="big-img">
    <img :src="url" alt srcset />
    <div class="newsdesc-tit">
      <h1>{{title}}</h1>
      <h2>{{en}}</h2>
    </div>
  </div>
</template>
<script>
export default {
    name:'Newstop',
    props:['title','en','url']
}
</script>
<style lang="less" scoped>
.big-img {
  position: relative;
  /deep/img{
      width:100%;
      margin: 0 auto;
  }
  .newsdesc-tit {
    position: absolute;
    top: 30%;
    left: 20%;

    h1, h2 {
      font-weight: normal;
    }

    h1 {
      font-size: 72px;
      color: #333;
    }

    h2 {
      font-size: 36px;
      color:#999999;
    }
  }
}
</style>