<template>
  <div class="guide">
    <Normaltop
      :url="qiniuUrl"
      title="报考指南"
      en="Application Guide"
    ></Normaltop>
    <div class="guide-cont">
      <!-- <ul class="guide-menu flex">
        <li @click="goAssignBlock('block0',50)">报考须知</li>
        <li @click="goAssignBlock('block1',50)">在线注册报名</li>
      </ul> -->
      <div class="part" ref="block0">
        <h2 class="part-title text-center">报考须知</h2>
        <el-row :gutter="40">
          <el-col :span="7">
            <h3 class="part-h3">报考条件：</h3>
            <p class="part-cont">
              1.考生需满18周岁；
              <br />2.报考者必须拥有中专以上学历；
              <br />3.考生应可以熟练的使用普通话；
              <br />4.熟悉教学行业的政策规范；
              <br />5.熟练使用制作课件的相关软件。
            </p>
          </el-col>
          <el-col :span="9">
            <h3 class="part-h3">适合报考人群：</h3>
            <p class="part-cont">
              1.已经在从事教育行业的教师和培训师等人员；
              <br />2.日常想兼职教学工作的人员；
              <br />3.想通过网络教授职业技能或其他合法技能的人员；
              <br />4.希望将来能从事在线教育行业工作并发展的人员；
              <br />5.在线教育行业的专业管理人员、企业管理人员等。
            </p>
          </el-col>
          <el-col :span="8">
            <h3 class="part-h3">报考费用说明:</h3>
            <p class="part-cont">
              1.评测费用一共需要{{
                this.$price
              }}，内含课程费用、模拟练习及考试费用、证书制作费用；
              <br />2.评测费用为一次性付费，付费完成后不可退款，可申请开具纸质发票
              <br />3.<span class="font_red"
                >付费完成后可获得无限次模拟练习和每个科目的一次补考机会。</span
              >
            </p>
          </el-col>
        </el-row>
        <h3 class="part-h3">报考方式：</h3>
        <div v-for="item in methods" :key="item.name">
          <div class="flex-col-center methods">
            <img class="icon" :src="item.url" alt srcset />
            <div>
              <h3 class="method-name">{{ item.name }}</h3>
              <p class="part-cont" v-html="item.cont"></p>
            </div>
          </div>
        </div>
      </div>

      <div class="part notop">
        <h2 class="part-h3">颁证条件：</h2>
        <p class="part-cont">
          网培师考试一共三个评测科目，“教学综合能力评测”“网培政策规范评测”“教学数字化能力评测”，学员需要通过不同的考试从而获得不同等级的证书，具体规则如下：
        </p>
        <p class="part-cont notop">
          初级网培师资格证书：学员只需要通过“教学综合能力评测”和“网培政策规范评测”两科考试，即可获得初级网培师证书。
        </p>
        <p class="part-cont notop">
          中级网培师资格证书：<span class="font_red"
            >学员需要通过“教学综合能力评测”、“网培政策规范评测”和“教学数字化能力评测”三科考试，才可获得中级网培师资格证书。</span
          >
        </p>
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          :header-cell-style="{ color: '#960f23', borderColor: '#ccc' }"
          :span-method="objectSpanMethod"
          center
          :cell-style="cellStyle"
        >
          <el-table-column prop="result" label="" width="180">
          </el-table-column>
          <el-table-column prop="grade1" label="教学综合能力评测" width="180">
          </el-table-column>
          <el-table-column prop="grade2" label="网培政策规范评测" width="180">
          </el-table-column>
          <el-table-column prop="grade3" label="教学数字化能力评测">
          </el-table-column>
          <el-table-column prop="certs" label="颁发证书"></el-table-column>
        </el-table>
      </div>

      <div class="part">
        <h2 class="part-title text-center">高级网培师申报须知</h2>
        <el-row :gutter="60">
          <el-col :span="8">
            <h3 class="part-h3">申报条件:</h3>
            <p class="part-cont">
              1.考生具有国家承认本科以上学历；
              <br />2.已获得中级网培师资格证书一年以上；
              <br />3.深度了解教学方式及政策规范，热爱教学并取得过优异成就；
              <br />4.熟悉现代化教育培训体系和知识，能熟练使用各类教学辅助工具；
            </p>
          </el-col>
          <el-col :span="7">
            <h3 class="part-h3">适合申报人群:</h3>
            <p class="part-cont">
              1.从事在线教育行业多年并取得优异成绩的资深从业人员；
              <br />2.在网络教学或培训的平台达成优异成就的资深从业人员；
              <br />3.经常从事大型教学或讲座的职业人员；
            </p>
          </el-col>
          <el-col :span="9">
            <h3 class="part-h3">报考费用说明:</h3>
            <p class="part-cont">
              1.申报高级网培师资格证需缴纳1200元，内包含专家评审费、管理服务费和证书制作费；
              <br />
              2.评测费用是一次性付费，付费完成后不可退款，可开具正规发票；
            </p>
          </el-col>
        </el-row>
        <h3 class="part-h3">申报方式：</h3>
        <div v-for="item in gmethods" :key="item.name">
          <div class="flex-col-center methods">
            <img class="icon" :src="item.url" alt srcset />
            <div>
              <h3 class="method-name">{{ item.name }}</h3>
              <p class="part-cont" v-html="item.cont"></p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div ref="block1" v-loading="loading">
        <div class="part">
          <h2 class="part-title text-center">网培师报考在线提交</h2>
          <el-card class="form-box">
            <el-form
              :model="form"
              status-icon
              :rules="rules"
              ref="form"
              label-width="120px"
              class="demo-form"
              label-position="left"
            >
              <el-form-item label="姓名：" prop="name">
                <el-input v-model="form.name" placeholder="请输入本人真实姓名"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="短信验证码：" prop="codes">
                <el-input placeholder="请输入验证码" class="code-box" v-model="form.codes"></el-input>
                <el-button class="getcode-btn" type="default">获取验证码</el-button>
                <el-button class="getcode-btn" @click="getCode" :disabled="show">{{sendCon}}</el-button>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  type="password"
                  v-model="form.password"
                  autocomplete="off"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="rpassword">
                <el-input
                  type="password"
                  v-model="form.rpassword"
                  autocomplete="off"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item label prop="agree" class="no-label">
                <el-checkbox type="password" v-model="form.agree"><a  href="/xy" target="view_window">同意网培师平台用户协议</a></el-checkbox>
              </el-form-item>
              <el-form-item class="text-center no-label">
                <el-button type="danger" @click="register('form')">在线报名 ></el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
let _this
import Normaltop from '../components/normaltop'
export default {
  name: 'Certificate',
  components: { Normaltop },
  data() {
    var validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        if (/^1[3456789]\d{9}$/.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.rpassword !== '') {
          this.$refs.form.validateField('rpassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入验证码'))
      } else if (value.length !== 6) {
        callback(new Error('验证码输入有误!'))
      } else {
        callback()
      }
    }
    var validateagree = (rule, value, callback) => {
      if (value) {
        callback()
      } else {
        callback(new Error('您未同意网培师平台用户协议'))
      }
    }
    return {
      show: false,
      sendCon: '获取验证码',
      loading: false,
      qiniuUrl: this.$qiniuUrl + 'guide/banner.png',
      methods: [
        {
          name: '在线报名',
          url: require('../assets/images/guide/img2.png'),
          cont:
            '1.考生报名需在线注册并前往个人中心完善本人真实资料（<span class="font_red">信息真实性会影响证书的生成及证书发放问题，请如实填写！</span>）；<br/>2.考生在缴费后即可在正式评测开放时间内进行考试，正式考试开放时间在每月会定期开放，请考生注意时间公告；',
        },
        {
          name: '在线评测',
          url: require('../assets/images/guide/img3.png'),
          cont: `
          1.<span class="font_red">参加考试时请考生使用佩戴摄像头的电脑进行考试，在正式考试时将有摄像头认证及监考，若发现违反考试规定，则视为作弊处理！</span><br/>
          2.网培师测评的三个评测模块均采用在线答题方式，试题类型分为三种类型（单选题、多选题及判断题），请考生认真审题；<br/>
          3.每项评测试题数量为100~200道选择题，考试时长为90分钟或120分钟，考试结果达到60分以上（含60分）则视为评测通过；<br/>
          4.<span class="font_red">考试成绩不通过需要补考的考生，请在考试后的一个月内完成未通过的评测，如逾期平台则视为该考生放弃考试并取消补考机会！</span>
          `,
        },
        {
          name: '获取证书',
          url: require('../assets/images/guide/img4.png'),
          cont: `
             1.考生在评测完成后请耐心等待，平台将在7天内根据考生不同的成绩发放相应电子证书； <br/>
            2.由于纸质证书制作需要时间，制作完成后平台将根据用户填写的地址邮寄证书，请学员注意短信通知，及时查看物流信息；<br/>
            3.教育区块链银行会为已获得证书的学员颁发数字证书，详情请登录微信小程序“EDC学习银行”查看。
            `,
        },
      ],
      gmethods: [
        {
          name: '在线申报',
          url: require('../assets/images/guide/img5.png'),
          cont:
            '1.申报高级网培师资格证需本人已获中级网培师资格证书1年以上；<br/>2.申报需上传1~3个本人优秀的教学视频；<br/>3.所在单位或平台的推荐信和个人已取得的其他辅助资料（如教学培训相关的荣誉证明证书等）；',
        },
        {
          name: '专家评审',
          url: require('../assets/images/guide/img9.png'),
          cont:
            '提交申报材料后，网培师认证中心将定期组织专家评审会，通过对教学视频及其他材料进行评审，综合评价给予评审结果。',
        },
        {
          name: '获得证书',
          url: require('../assets/images/guide/img6.png'),
          cont:
            '1.评审通过后，将由中国商业联合会颁发评审证明及高级网培师资格证书；<br/>2.同时教育区块链学习银行也将颁发相应的数字证书。',
        },
      ],
      form: {
        name: '',
        mobile: '',
        codes: '',
        agree: '',
        password: '',
        rpassword: '',
      },
      rules: {
        mobile: {
          required: true,
          validator: validateMobile,
          message: '请输入手机号',
          trigger: ['blur', 'change'],
        },
        name: {
          required: true,
          message: '请输入本人真实姓名',
          trigger: ['blur', 'change'],
        },
        password: { required: true, validator: validatePass, trigger: 'blur' },

        rpassword: {
          required: true,
          validator: validatePass2,
          trigger: 'blur',
        },

        codes: {
          required: true,
          validator: validateCode,
          trigger: 'blur',
        },
        agree: {
          validator: validateagree,
          required: true,
          message: '您未同意网培师平台用户协议',
          trigger: 'change',
        },
      },
      tableData: [
        {
          result: '评测结果',
          grade1: '及格',
          grade2: '不及格',
          grade3: '不及格',
          certs: '无',
        },
        {
          result: '评测结果',
          grade1: '及格',
          grade2: '及格',
          grade3: '不及格',
          certs: '初级网培师资格证书',
        },
        {
          result: '评测结果',
          grade1: '及格',
          grade2: '及格',
          grade3: '及格',
          certs: '中级网培师资格证书',
        },
      ],
    }
  },
  created() {
    _this = this
    this.init()
  },
  methods: {
    init() {},
    goAssignBlock(el, speed) {
      let windowH = window.innerHeight //浏览器窗口高度
      let h = this.$refs[el].offsetHeight //模块内容高度
      let t = this.$refs[el].offsetTop //模块相对于内容顶部的距离
      let top = t - (windowH - h) / 2 + 200 //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop //滚动条距离顶部高度
      let currentTop = scrollTop //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop)
            requestId = window.requestAnimationFrame(step)
          } else {
            window.cancelAnimationFrame(requestId)
          }
          //向下滚动
          currentTop += speed
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - speed)
            requestId = window.requestAnimationFrame(step)
          } else {
            window.cancelAnimationFrame(requestId)
          }
          //向上滚动
          currentTop -= speed
        }
      }
      window.requestAnimationFrame(step)
    },
    register(formName) {
      _this.loading = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'register', this.form)
            .then(data => {
              if (data.data.code == 200) {
                _this.$message.success('注册成功')
                _this.$router.push({ name: 'Login' })
                _this.loading = false
              } else {
                _this.$message.error(data.data.msg)
                _this.loading = false
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
              _this.loading = false
            })
        } else {
          return false
        }
      })
    },
    getCode() {
      let mobile = this.form.mobile
      let params = {
        type: 'reg',
        mobile: this.form.mobile,
      }
      if (/^1[3456789]\d{9}$/.test(mobile)) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'sendCode', params)
          .then(data => {
            if (data.data.code == 200) {
              _this.countdown()
              _this.$message.success('验证码已发送')
            } else {
              _this.$message.error(data.data.msg)
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')
            console.log(err)
          })
      } else {
        this.$message.warning('请输入正确格式的手机号')
        return
      }
    },
    countdown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT

        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.show = true
            this.count--
            this.sendCon = this.count + '秒后再次获取'
          } else {
            this.show = false
            clearInterval(this.timer)
            this.timer = null
            this.sendCon = '获取验证码'
          }
        }, 1000)
      }
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    cellStyle() {
      return 'border-color:#ccc!important;'
    },
  },
}
</script>
<style lang="less" scoped>
.guide {
  font-weight: normal;
  font-size: 16px;
  img {
    width: 100%;
  }
  /deep/.el-button--danger {
    background: #e10133;
    width: 150px;
    color: #fff;
  }
  .guide-cont {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding: 70px 0;
    .guide-menu {
      height: 35px;
      li {
        margin-right: 30px;
        font-size: 24px;
        color: #000005;
        cursor: pointer;
        &:hover {
          border-bottom: 2px solid #f5002e;
        }
      }
    }
    .notop {
      padding: 0px !important;
    }
    .part {
      width: 60%;
      min-width: 1100px;
      margin: 0 auto;
      position: relative;
      padding-top: 50px;
      .methods {
        padding: 25px 0;
      }
      .part-title {
        color: #000005;
        font-weight: bold;
        font-size: 36px;
        padding-bottom: 50px;
      }
      .method-name {
        color: #960f23;
        padding-top: 10px;
      }
      .part-h3 {
        font-size: 22px;
        padding-top: 20px;
      }
      .part-cont {
        padding-top: 16px;
        color: #333;
        line-height: 2;
      }
      .part-indent {
        text-indent: 20px;
      }

      .icon {
        width: 120px;
        // height: 120px;
        margin-right: 30px;
      }
      .form-box {
        width: 620px;
        margin: 0 auto;
        /deep/.el-card__body {
          padding: 30px 55px 30px 42px;
        }
        .code-box {
          width: 200px;
        }
        .getcode-btn {
          width: 180px;
          border-color: #e10133;
          color: #e10133;
          margin-left: 23px;
        }
        /deep/.no-label .el-form-item__content {
          margin: 0 !important;
        }
      }
      /deep/.el-table {
        margin: 30px 0px;
      }
      /deep/.el-table th > .cell {
        text-align: center;
      }

      /deep/.el-table .cell {
        text-align: center;
      }
      .el-table td,
      .el-table th.is-leaf,
      .el-table--border,
      .el-table--group {
        border-color: #ccc;
      }
      .el-table--border::after,
      .el-table--group::after,
      .el-table::before {
        background-color: #ccc;
      }
      /deep/.el-table tr:nth-child(1) td:nth-child(3) {
        color: red;
      }
      /deep/.el-table tr:nth-child(1) td:nth-child(4) {
        color: red;
      }
      /deep/.el-table tr:nth-child(2) td:nth-child(3) {
        color: red;
      }
      /deep/.el-table td:nth-child(2) {
        color: #005efb;
      }
      /deep/.el-table tr:nth-child(2) td:nth-child(1) {
        color: #005efb;
      }
      /deep/.el-table tr:nth-child(3) td:nth-child(1) {
        color: #005efb;
      }
      /deep/.el-table tr:nth-child(3) td:nth-child(3) {
        color: #005efb;
      }
    }
    .table-box {
      border-top: 7px solid #e10133 !important;
      border-bottom: 7px solid #e10133 !important;
    }
    .table {
      // border:4px solid #E8EBEE;

      color: #333333;
      font-size: 18px;
      line-height: 36px;

      td {
        padding: 35px 25px;
        border: 4px solid #e8ebee;
        border-collapse: collapse;
      }
      h3 {
        font-weight: bolder;
        font-size: 24px;
      }
    }
  }
}
</style>
